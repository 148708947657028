import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UIDesignModule } from 'src/app/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { MapDialogComponent } from './map-dialog/map-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AddCharacterDialogComponent } from './add-character-dialog/add-character-dialog.component';
import { AddQuizzesDialogComponent } from './add-quizzes-dialog/add-quizzes-dialog.component';
import { AddImagesDialogComponent } from './add-images-dialog/add-images-dialog.component';
import { LoaderComponent } from './loader/loader.component';



@NgModule({
    declarations: [HeaderBarComponent, NavSidebarComponent, MapDialogComponent, AddCharacterDialogComponent, AddQuizzesDialogComponent, AddImagesDialogComponent, LoaderComponent],
    imports: [
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        RouterModule,
        CommonModule,
        UIDesignModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        HeaderBarComponent,
        NavSidebarComponent,
        MapDialogComponent,
        LoaderComponent
    ]
})
export class GuiltCommonModule { }
