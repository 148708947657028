import {versionNumber} from './version';

export const environment = {
    production: true,
    test: false,
    server: {
        protocol: 'https',
        host: 'www.guiltapp.it',
        port: 443,
        apiPath: ''
    },
    version: versionNumber,
    currentUser: null
};
