import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PlaceService } from 'src/app/services/place.service';
import { StoryService } from 'src/app/services/story.service';

@Component({
  selector: 'app-add-quizzes-dialog',
  templateUrl: './add-quizzes-dialog.component.html',
  styleUrls: ['./add-quizzes-dialog.component.scss']
})
export class AddQuizzesDialogComponent implements OnInit {
    stories;
    quizzes;
    filteredQuizzes = [];
    selectedQuizzes = [];
    constructor(
        private placeService: PlaceService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) { }

    ngOnInit(): void {

        this.initData();
    }


    initData() {
        this.placeService.getList().then((res: any) => {
            if (res.success) {
                this.quizzes = res.storyplaces;
                this.quizzes.forEach(quiz => {
                    if (this.config.data.storyQuizzes.some(q => quiz.id === q.id)) {
                        return
                    } else {
                        this.filteredQuizzes.push(quiz);
                    }
                })
            }

        });
    }

    addAndClose() {
        this.ref.close(this.selectedQuizzes);
    }
}
