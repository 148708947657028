import { transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { slider } from 'src/app/utils/animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slider]
    
})
export class AppComponent {
    title = 'guiltAdminPanel';
    animationState: number;

    constructor(public router: Router, private route: ActivatedRoute, private primengConfig: PrimeNGConfig) {
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
    

    onActivate($event) {
        this.animationState = this.route.firstChild.snapshot.firstChild.data['routeIdx'];
    }
}
