<aside id="side-nav">
    <nav>
        <div>
            <a routerLink="/story-catalog" routerLinkActive="active">
                <i class="gt gt-catalog"></i>
                Catalogo Storie
            </a>
        </div>
        <div>
            <a routerLink="/image-library" routerLinkActive="active">
                <i class="gt gt-image"></i>
                Libreria Immagini
            </a>
        </div>
        <div>
            <a routerLink="/character-list" routerLinkActive="active">
                <i class="gt gt-suspect"></i>
                Personaggi
            </a>
        </div>
        <div>
            <a routerLink="/quiz-clue-archive" routerLinkActive="active">
                <i class="gt gt-archive"></i>
                Archivio Enigmi e Indizi
            </a>
        </div>
    </nav>
</aside>
<p-confirmDialog #cd>
    <p-footer>
        <button pRipple pButton class="button-danger" (click)="cd.reject()">Annulla</button>
        <button pRipple pButton class="button-primary" (click)="cd.accept()">Abbandona modifiche e prosegui</button>
    </p-footer>
</p-confirmDialog>