<section class="quiz-table-wrapper" [ngStyle]="{'height': 'calc(100% - 90px)'}">
    <p-table #ct [value]="filteredQuizzes" class="quiz-table" [(selection)]="selectedQuizzes"
        styleClass="p-datatable-gridlines" [rowHover]="true" [scrollable]="true" scrollHeight="flex"
        [globalFilterFields]="['internal_name', 'name','story_list']">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:3rem !important;">
                <col>
                <col>
                <col style="width:9rem !important">
                <col style="width:9rem !important">
                <col>
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr class="cols-header">
                <th style="width:3rem !important; max-width:3rem !important">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>

                <th pSortableColumn="internal_name">
                    Rif. interno
                    <p-sortIcon field="internal_name"></p-sortIcon>
                </th>
                <th pSortableColumn="name">
                    Nome
                    <p-sortIcon field="name"></p-sortIcon>
                </th>

                <th pSortableColumn="identify_with_qr_code" class="p-text-center" style="width:9rem !important">
                    QRcode
                    <p-sortIcon field="identify_with_qr_code"></p-sortIcon>
                </th>
                <th pSortableColumn="identify_with_position" class="p-text-center" style="width:9rem !important">
                    Posizione
                    <p-sortIcon field="identify_with_position"></p-sortIcon>
                </th>
                <th pSortableColumn="story_list">
                    Storie associate
                    <p-sortIcon field="story_list"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-quiz>
            <tr>
                <td style="width:3rem !important">
                    <p-tableCheckbox [value]="quiz"></p-tableCheckbox>
                </td>
                <td>
                    <span>{{quiz.internal_name}}</span>
                </td>
                <td>
                    <span>{{quiz.name}}</span>
                </td>
                <td style="width:9rem !important" class="p-text-center">
                    <i class="quiz-icon pi" [ngClass]="quiz.identify_with_qr_code ? 'pi-check' : 'pi-times'"></i>
                </td>
                <td style="width:9rem !important" class="p-text-center ">
                    <i class="quiz-icon pi" [ngClass]="quiz.identify_with_position ? 'pi-check' : 'pi-times'"></i>
                </td>
                <td>
                    <div *ngFor="let story of quiz.stories">{{story.title}}</div>
                </td>
            </tr>
        </ng-template>

    </p-table>
    <section class="add-btn-wrapper">
        <button pButton pRipple label="Aggiungi Enigmi" (click)="addAndClose()"
            class="p-button-raised p-button-rounded button-primary"></button>
    </section>
</section>