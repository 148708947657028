import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class GroupsService extends RestService {

    constructor(http: HttpClient) {
        super(http, 'dashboardApi');

    }

    getList() {
        return this.http
            .get(`${this.getApiUrl()}/groups/`, {
                headers: this.getHeaders()
            }).toPromise()
    }
}