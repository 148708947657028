import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ListboxModule } from 'primeng/listbox';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToolbarModule } from 'primeng/toolbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenuModule } from 'primeng/menu';

@NgModule({
    imports: [
        TableModule,
        SplitButtonModule,
        DropdownModule,
        PasswordModule,
        MultiSelectModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        CalendarModule,
        DynamicDialogModule,
        ListboxModule,
        InputTextModule,
        InputMaskModule,
        CardModule,
        TabViewModule,
        TabMenuModule,
        CheckboxModule,
        PanelModule,
        InputSwitchModule,
        DividerModule,
        RippleModule,
        MessagesModule,
        MessageModule,
        ProgressBarModule,
        SelectButtonModule,
        ToastModule,
        FileUploadModule,
        ToggleButtonModule,
        ScrollPanelModule,
        ToolbarModule,
        AutoCompleteModule,
        EditorModule,
        RadioButtonModule,
        DialogModule,
        ProgressSpinnerModule,
        MenuModule
    ],
    exports: [
        TableModule,
        SplitButtonModule,
        DropdownModule,
        PasswordModule,
        MultiSelectModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        CalendarModule,
        DynamicDialogModule,
        ListboxModule,
        InputTextModule,
        InputMaskModule,
        CardModule,
        TabViewModule,
        TabMenuModule,
        CheckboxModule,
        PanelModule,
        InputSwitchModule,
        DividerModule,
        RippleModule,
        MessagesModule,
        MessageModule,
        ProgressBarModule,
        SelectButtonModule,
        ToastModule,
        FileUploadModule,
        ToggleButtonModule,
        ScrollPanelModule,
        ToolbarModule,
        AutoCompleteModule,
        EditorModule, 
        RadioButtonModule,
        DialogModule,
        ProgressSpinnerModule,
        MenuModule
    ],
})
export class UIDesignModule {
}
