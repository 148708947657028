import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateGuard } from './guards/deactivate.guard';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'login',
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
                data: { routeIdx: 0 }
            }
        ]
    },
    {
        path: 'password-change',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/password-change/password-change.module').then(m => m.PasswordChangePageModule),
                data: { routeIdx: 99 }
            }
        ]
    },
    {
        path: 'story-catalog',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/story-catalog/story-catalog.module').then(m => m.StoryCatalogPageModule),
                data: { routeIdx: 1 }

            }
        ]
    },
    {
        path: 'create-story/:id',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-story/create-edit-story.module').then(m => m.CreateEditStoryPageModule),
                data: { routeIdx: 2 }
            }
        ]
    },
    {
        path: 'edit-story/:id',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-story/create-edit-story.module').then(m => m.CreateEditStoryPageModule),
                data: { routeIdx: 2},
            }
        ]
    },
   
    {
        path: 'image-library',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/image-library/image-library.module').then(m => m.ImageLibraryPageModule),
                data: { routeIdx: 3 }
            }
        ]
    },
    {
        path: 'create-image',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-image/create-edit-image.module').then(m => m.CreateEditImagePageModule),
                data: { routeIdx: 4 }
            }
        ]
    },
    {
        path: 'edit-image/:id',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-image/create-edit-image.module').then(m => m.CreateEditImagePageModule),
                data: { routeIdx: 4 }
            }
        ]
    },
    {
        path: 'character-list',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/character-list/character-list.module').then(m => m.CharacterListPageModule),
                data: { routeIdx: 5 }
            }
        ]
    },
    {
        path: 'create-character',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-character/create-edit-character.module').then(m => m.CreateEditCharacterPageModule),
                data: { routeIdx: 6 }
            }
        ]
    },
    {
        path: 'edit-character/:id',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-character/create-edit-character.module').then(m => m.CreateEditCharacterPageModule),
                data: { routeIdx: 6 }
            }
        ]
    },
    {
        path: 'quiz-clue-archive',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/quiz-clue-archive/quiz-clue-archive.module').then(m => m.QuizClueArchivePageModule),
                data: { routeIdx: 7 }
            }
        ]
    },
    {
        path: 'create-quiz',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-quiz/create-edit-quiz.module').then(m => m.CreateEditQuizPageModule),
                data: { routeIdx: 8 }
            }
        ]
    },
    {
        path: 'edit-quiz/:id',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/create-edit-quiz/create-edit-quiz.module').then(m => m.CreateEditQuizPageModule),
                data: { routeIdx: 8 }
            }
        ]
    },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'corrected' })
    ],
    exports: [RouterModule],
    providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }
