import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {
    user;
    constructor(
        private authService: AuthenticationService,
        private confirmationService: ConfirmationService,
        private router: Router
        ) { }

    ngOnInit(): void {
        if (this.authService.isLoggedIn()) {
            this.user = environment.currentUser;
        }
    }

    logout() {
        if(localStorage.getItem('isEditing')) {
            this.confirmationService.confirm({
                message: `Stai per abbandonare le modifiche senza salvare, vuoi procedere?`,
                icon: 'pi  pi-exclamation-triangle',
                header: `Abbandona modifiche`,
                acceptLabel: 'Salva la storia e prosegui',
                rejectLabel: 'Annulla',
                accept: () => {
                    this.authService.logout();
                },
                reject: () => {

                }
            })
        } else {
            this.authService.logout();
        }
    }

    confirmExit(route) {
        if (localStorage.getItem('isEditing')) {
            this.confirmationService.confirm({
                message: `Stai per abbandonare le modifiche senza salvare, vuoi procedere?`,
                icon: 'pi  pi-exclamation-triangle',
                header: `Abbandona modifiche`,
                acceptLabel: 'Salva la storia e prosegui',
                rejectLabel: 'Annulla',
                accept: () => {
                    this.router.navigate([route]);
                },
                reject: () => {

                }
            })
        } else {
            this.router.navigate([route]);
        }
    }
}
