import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class StoryService extends RestService {

    constructor(http: HttpClient) {
        super(http, 'dashboardApi');

    }

    getList() {
        return this.http
            .get(`${this.getApiUrl()}/stories/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    create(formData) {
        return this.http
            .post(`${this.getApiUrl()}/story-create/`,
                formData,
                {
                    headers: this.getHeaders()
                }).toPromise()
    }

    delete(id) {
        return this.http
            .delete(`${this.getApiUrl()}/story/${id}/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    get(id) {
        return this.http
            .get(`${this.getApiUrl()}/story/${id}/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    update(id, formData) {
        return this.http
            .post(`${this.getApiUrl()}/story/${id}/`,
                formData, {
                headers: this.getHeaders()
            }).toPromise()
    }

    requestPublication(id) {
        return this.http
            .post(`${this.getApiUrl()}/story/${id}/publication/`,
                { action: 'request' },
                {
                    headers: this.getHeaders()
                }).toPromise()
    }

    approvePublication(id) {
        return this.http
            .post(`${this.getApiUrl()}/story/${id}/publication/`,
                { action: 'approve' },
                {
                    headers: this.getHeaders()
                }).toPromise()
    }

    denyPublication(id) {
        return this.http
            .post(`${this.getApiUrl()}/story/${id}/publication/`,
                { action: 'deny' },
                {
                    headers: this.getHeaders()
                }).toPromise()
    }
}