import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imageType'
})
export class ImageTypePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case 'PLACE':
                return 'Luogo'
            case 'CHARACTER':
                return 'Personaggio'
            default:
                return 'Generale'
        }
    }
}
