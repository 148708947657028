import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ImageService } from 'src/app/services/image.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-image-detail-dialog',
    templateUrl: './image-detail-dialog.component.html',
    styleUrls: ['./image-detail-dialog.component.scss']
})
export class ImageDetailDialogComponent implements OnInit {
    refErrorHeader;
    refErrorMessage;
    display;
    user = environment.currentUser;
    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private imageService: ImageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        private router: Router,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
    }


    closeDialog() {
        this.ref.close();
    }

    selectImage(isSelected) {
        const imgSelected = {
            id: this.config.data.image.id,
            selected: isSelected
        }
        this.ref.close(imgSelected);
    }

    editImage(image) {
        this.ref.close();
        this.router.navigate(['edit-image', image.id])
    }

    editImageFromStory() {
        this.confirmationService.confirm({
            message: `Stai per modificare l'immagine ${this.config.data.image.title}, vuoi procedere?`,
            icon: 'pi  pi-exclamation-triangle',
            header: `Modifica Immagine`,
            acceptLabel: 'Salva la storia e prosegui',
            rejectLabel: 'Annulla',
            key: 'ei',
            accept: () => {
                this.ref.close({ image: this.config.data.image, edit: true})
            },
            reject: () => {

            }
        })
    }

    removeFromStory() {
        this.ref.close({ image: this.config.data.image, remove: true})
    }

    deleteImage(image) {
        this.imageService.get(image.id).then((res: any) => {
            if (res.is_ref) {
                this.refErrorHeader = 'Immagine associata';
                this.refErrorMessage = 'L\'immagine risulta associata, non è possibile eliminarla';
                this.display = true;
            } else {
                this.confirmDeleteImage(image);
            }
        })
    }
    confirmDeleteImage(image) {
        this.confirmationService.confirm({
            message: `Stai per eliminare l'immagine ${image.title}, vuoi procedere?`,
            icon: 'pi  pi-exclamation-triangle',
            header: 'Elimina Immagine',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                this.imageService.delete(image.id).then((res: any) => {
                    if (res.success) {
                        this.ref.close(true)
                    }
                }).catch((err) => {
                    this.messageService.add({ severity: 'error', summary: 'Immagine non eliminata', detail: 'Si è verificato un errore, riprova più tardi' });
                });
            },
            reject: () => {

            }
        })
    }
}
