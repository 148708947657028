<p-scrollPanel [style]="{width: '100%', height: 'calc(100% - 90px)'}">
    <section class="image-grid-wrapper">
        <div class="p-shadow-3" *ngFor="let image of filteredImages" (click)="toggleImageSelection(image)"
            [ngStyle]="{'backgroundImage': 'url(' + image.image + ')'}" [class.selected]="checkSelected(image.id)">
            <!-- <p-checkbox *ngIf="!config.data.isCover" [value]="image" [ngModel]="selectedImages" (ngModelChange)="toggleSelectedImages()"></p-checkbox> -->
            <div class="hover-elements">
                <div class="image-title">{{image.title}}</div>
                <button pButton class="p-button-text" icon="pi pi-search" (click)="showImageDetail($event, image)"></button>
            </div>
        </div>
    </section>
</p-scrollPanel>
<section class="add-btn-wrapper">
    <button pButton pRipple [label]="config.data.isCover ? 'Imposta Immagine' : 'Aggiungi Immagini'" (click)="addAndClose()"
        class="p-button-raised p-button-rounded button-primary"></button>
</section>