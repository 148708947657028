<div class="map-wrapper" *ngIf="marker">
    <google-map width="100%" [center]="center" [zoom]=zoom (mapClick)="selectAddress($event)">
        <map-marker #markerElem [position]="marker.position" [label]="marker.label" [title]="marker.title"
            [options]="marker.options">
        </map-marker>

    </google-map>
    <div style="width:100%; text-align: center; padding:20px 0 10px; font-weight:700;">
        {{address.description}}
    </div>
    <div class="btn-wrapper">
        <button type="button" pButton label="Conferma indirizzo" (click)="confirmAddress()" class="button-tertiary"></button>
    </div>
</div>