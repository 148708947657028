import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageDetailDialogComponent } from 'src/app/components/common/image-detail-dialog/image-detail-dialog.component';
import { ImageLibraryPage } from 'src/app/pages/image-library/image-library.page';
import { ImageService } from 'src/app/services/image.service';
import { StoryService } from 'src/app/services/story.service';

@Component({
    selector: 'app-add-images-dialog',
    templateUrl: './add-images-dialog.component.html',
    styleUrls: ['./add-images-dialog.component.scss']
})
export class AddImagesDialogComponent implements OnInit {
    stories;
    images;
    filteredImages = [];
    selectedImages = [];
    constructor(
        private imageService: ImageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public dialogService: DialogService
    ) { }

    ngOnInit(): void {
        this.initData();
    }


    initData() {
        this.imageService.getList().then((res: any) => {
            if (res.success) {
                this.images = res.imagemedias;
                if (this.config.data.storyImages){
                    if (this.config.data.isCover) {
                        this.filteredImages = res.imagemedias.filter(img => this.config.data.storyImages.id !== img.id);
                    } else {
                        this.images.forEach(img => {
                            if (this.config.data.storyImages.some(image => img.id === image.id)) {
                                return
                            } else {
                                this.filteredImages.push(img);
                            }
                        })
                    }
                } else {
                    this.filteredImages = res.imagemedias;
                }
               
            }
        });
    }
    showImageDetail(event, image) {
        event.stopPropagation();
        const ref = this.dialogService.open(ImageDetailDialogComponent, {
            width: '100%',
            height: '100%',
            showHeader: false,
            styleClass: 'image-dialog-wrapper',
            data: {
                image: image,
                editStory: true,
                isSelected: this.checkSelected(image.id)
            }
        });

        ref.onClose.subscribe((image: any) => {
            if (image) {
                this.imageSelectionFromDetail(image.id, image.selected)
            }
        });
    }

    addAndClose() {
        this.ref.close(this.selectedImages);
    }

    toggleImageSelection(image) {
        if (this.config.data.isCover) {
            if (this.selectedImages.some(img => image.id === img.id)) {
                this.selectedImages = [];
            } else {
                this.selectedImages = [image];
            }
        } else {
            if (this.selectedImages.some(img => image.id === img.id)) {
                this.selectedImages = this.selectedImages.filter(img => image.id !== img.id)
            } else {
                this.selectedImages.push(image);
            }
        }
    }

    imageSelectionFromDetail(id, selected) {
        if (this.config.data.isCover) {
            if (selected) {
                this.selectedImages = this.images.filter(img => img.id === id);
            } else {
                this.selectedImages = []
            }
        } else {
            if (selected) {
                let image = this.images.find(img => img.id === id);
                this.selectedImages.push(image);
            } else {
                this.selectedImages = this.selectedImages.filter(img => id !== img.id);
            }
        }
    }

    checkSelected(imageId) {
        return this.selectedImages.some(img => imageId === img.id)
    }
}
