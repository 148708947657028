import { NgModule } from '@angular/core';
import { ImageTypePipe } from './image-type.pipe';


const COMPONENTS = [
    ImageTypePipe,
];


@NgModule({
    declarations: COMPONENTS,
    imports: [],
    exports: COMPONENTS,
})
export class PipesModule {
}