
<section class="character-table-wrapper" [ngStyle]="{'height': 'calc(100% - 90px)'}">
    <p-table #ct [value]="filteredCharacters" class="characters-table" [(selection)]="selectedCharacters"
        styleClass="p-datatable-gridlines" [rowHover]="true" [scrollable]="true" scrollHeight="flex"
        [globalFilterFields]="['name','story_list', 'date_created']">
        <ng-template pTemplate="colgroup">
            <colgroup>
                <col style="width:3rem !important;">
                <col>
                <col style="width:150px !important">
                <col>
                <col>
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr class="cols-header">
                <th style="width:3rem !important; max-width:3rem !important">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>

                <th pSortableColumn="name">
                    Nome
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="width:150px !important" class="p-text-center">
                    Immagine
                </th>

                <th pSortableColumn="date_created" class="p-text-center">
                    Creato il
                    <p-sortIcon field="date_created"></p-sortIcon>
                </th>
                <th pSortableColumn="story_list">
                    Storie associate
                    <p-sortIcon field="story_list"></p-sortIcon>
                </th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-character>
            <tr>
                <td style="width:3rem !important">
                    <p-tableCheckbox [value]="character"></p-tableCheckbox>
                </td>
                <td>
                    <span>{{character.name}}</span>
                </td>
                <td style="width:150px !important" class="p-text-center">
                    <img [src]="character.image.image" width="100">
                </td>
                <td class="p-text-center">
                    {{character.date_created | date:'dd/MM/yyyy'}}
                </td>
                <td>
                    <div *ngFor="let story of character.stories">{{story.title}}</div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <section class="add-btn-wrapper">
        <button pButton pRipple label="Aggiungi Personaggi" (click)="addAndClose()"
            class="p-button-raised p-button-rounded button-primary"></button>
    </section>
</section>