import {Injectable} from '@angular/core';
import {AbstractApiService} from './base.service';
import {HttpClient} from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
@Injectable()
export class RestService extends AbstractApiService {

    constructor(http: HttpClient, apiUrl: string) {
        super(http);
        this.apiUrl = apiUrl;
    }

    getAll() {
        return this.http
            .get(`${this.getApiUrl()}/`,
                {headers: this.getHeaders()}
        ).pipe(shareReplay(1)).toPromise();
    }

    get(id) {
        return this.http
            .get(`${this.getApiUrl()}/${id}/`,
                {headers: this.getHeaders()}
            ).toPromise();
    }
}
