import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-map-dialog',
    templateUrl: './map-dialog.component.html',
    styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent implements OnInit {
    @ViewChild(GoogleMap, { static: false }) map: GoogleMap

    zoom = 12
    center: google.maps.LatLngLiteral
    options: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8,
    }
    marker;
    address = {
        description: null,
        lat: null,
        lng: null,
        city: null
    };
    geocoder;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {
        this.geocoder = new google.maps.Geocoder;
    }


    ngOnInit(): void {
        if (this.config.data.lat !== 0.0 && this.config.data.lng !== 0.0) {
            this.center = {
                lat: this.config.data.lat,
                lng: this.config.data.lng
            }
            if (this.center.lat && this.center.lng) {
                this.addMarker();
            }
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                this.addMarker();
            });
        }
    }


    selectAddress(event: google.maps.MapMouseEvent) {
        this.center = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        }
        this.addMarker();
    }

    addMarker() {
        new Promise((resolve, reject) => {
            this.geocoder.geocode({ location: { lat: this.center.lat, lng: this.center.lng } }, (results, status) => {
                if (status === 'OK') {
                    this.address.description = results[0].formatted_address;
                    let city = this.checkLocality(results[0].address_components);
                    this.address.city = city[0].long_name;
                    this.address.lat = this.center.lat;
                    this.address.lng = this.center.lng;
                    resolve(this.address);
                }
            })
        }).then((response:any) => {
            this.marker = {
                position: {
                    lat: this.center.lat,
                    lng: this.center.lng,
                },
                title: response.description,
                options: { animation: google.maps.Animation.DROP },
            }
          
        });;
    }

    confirmAddress() {
        this.ref.close(this.address)
    }

    checkLocality(addressComponent) {
        if (addressComponent.some(component => component.types[0] === 'locality')) {
            return addressComponent.filter(component => component.types[0] === 'locality')
        } else if (addressComponent.some(component => component.types[0] === 'administrative_area_level_3')) {
            return addressComponent.filter(component => component.types[0] === 'administrative_area_level_3')
        } else if (addressComponent.some(component => component.types[0] === 'administrative_area_level_2')) {
            return addressComponent.filter(component => component.types[0] === 'administrative_area_level_2')
        } else if (addressComponent.some(component => component.types[0] === 'administrative_area_level_1')) {
            return addressComponent.filter(component => component.types[0] === 'administrative_area_level_1')
        } else if (addressComponent.some(component => component.types[0] === 'establishment')) {
            return addressComponent.filter(component => component.types[0] === 'establishment')
        }
    }
}

