<div class="close-btn-wrapper">
    <button pRipple pButton icon="pi pi-times" label="Chiudi" class="p-button-raised" (click)="closeDialog()"></button>
</div>
<div class="image-detail-container">
    <section class="image-container">
        <img [src]="config.data.image.image" class="p-shadow-5" />
    </section>
    <section>
        <div class="image-info-row">
            <div class="image-info-label">
                Titolo Immagine
            </div>
            <div>
                {{config.data.image.title}}
            </div>
        </div>
        <div class="image-info-row">
            <div class="image-info-label">
                Tipologia
            </div>
            <div>
                {{config.data.image.type | imageType}}
            </div>
        </div>
        <!-- <div class="image-info-row">
            <div class="image-info-label">
                Associata a
            </div>
            <div *ngFor="let story of config.data.image.story_list">
                {{story}}
            </div>
        </div> -->
        <div class="image-info-row" *ngIf="user && !config.data.isFromStory">
            <button pButton pRipple icon="pi pi-pencil" label="Modifica"
                class="p-button-raised p-button-rounded button-tertiary" *ngIf="!this.config.data.editStory && false"
                (click)="editImage(config.data.image)"></button>
            <button pButton pRipple icon="pi pi-check" [label]="config.data.isSelected ? 'Deseleziona' : 'Seleziona'"
                (click)="selectImage(!config.data.isSelected)" class="p-button-raised p-button-rounded"
                [ngClass]="config.data.isSelected ? 'button-tertiary' : 'button-secondary'"
                *ngIf="this.config.data.editStory"></button>
        </div>
        <div class="image-info-row" *ngIf="!this.config.data.editStory && user && !config.data.isFromStory && false">
            <!-- <a pRipple class="delete-link" (click)=deleteImage(config.data.image)>Elimina</a> -->
            <button pButton pRipple (click)="deleteImage(config.data.image)" icon="pi pi-trash"
                class="p-button-text button-danger text" label="Elimina"></button>
        </div>

        <div class="btn-wrapper" *ngIf="config.data.isFromStory">
            <button pButton pRipple icon="pi pi-pencil" label="Modifica"
                class="p-button-raised p-button-rounded button-tertiary"
                *ngIf="user"
                (click)="editImageFromStory()"></button>
            <button pButton pRipple (click)="removeFromStory()" icon="pi pi-trash"
                class="button-warning p-button-raised p-button-rounded" label="Rimuovi"></button>
        </div>

    </section>
</div>
<p-confirmDialog #cd>
    <p-footer>
        <button pRipple pButton class="button-danger" (click)="cd.reject()">Annulla</button>
        <button pRipple pButton class="button-primary" (click)="cd.accept()">Conferma</button>
    </p-footer>
</p-confirmDialog>
<p-confirmDialog #ei key="ei">
    <p-footer>
        <button pRipple pButton class="button-danger" (click)="ei.reject()">Annulla</button>
        <button pRipple pButton class="button-primary" (click)="ei.accept()">Salva la storia e prosegui</button>
    </p-footer>
</p-confirmDialog>
<p-dialog [(visible)]="display" styleClass="warning-dialog" [modal]="true" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
        <div><i class="pi pi-exclamation-triangle"></i>Immagine associata</div>
    </ng-template>
    {{refErrorMessage}}
    <p-footer>
        <p-button icon="pi pi-check" (click)="display=false" label="Ok" styleClass="button-warning"></p-button>
    </p-footer>
</p-dialog>