import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GuiltCommonModule } from './components/common/common.module';
import { UserService } from './services/user.service';
import { BaseRouteReuseStrategy, RouteReuseStrategy } from '@angular/router';
import { StoryService } from './services/story.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PipesModule } from './pipes/pipes.module';
import { DialogService } from 'primeng/dynamicdialog';
import { AppInitService } from './services/app-init.service';
import { CharacterService } from './services/character.service';
import { PlaceService } from './services/place.service';
import { GroupsService } from './services/groups.service';
import { ImageService } from './services/image.service';

export function initApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.init();
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        GuiltCommonModule,
        PipesModule
    ],
    providers: [
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService], multi: true},
        AuthenticationService,
        UserService,
        StoryService,
        CharacterService,
        PlaceService,
        GroupsService,
        ImageService,
        UserService,
        ConfirmationService,
        DialogService,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
