import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestService} from './rest.service';

@Injectable()
export class PlaceService extends RestService {

    constructor(http: HttpClient) {
        super(http, 'dashboardApi');

    }
    
    getList(){
        return this.http
            .get(`${this.getApiUrl()}/storyplaces/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    create(formData) {
        return this.http
            .post(`${this.getApiUrl()}/storyplace-create/`,
                formData,
                {headers: this.getHeaders()
            }).toPromise()
    }
 
    delete(id){
        return this.http
            .delete(`${this.getApiUrl()}/storyplace/${id}/`, {
                headers: this.getHeaders() 
            }).toPromise()
    }
    
    get(id) {
        return this.http
            .get(`${this.getApiUrl()}/storyplace/${id}/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    update(id, formData) {
        return this.http
            .post(`${this.getApiUrl()}/storyplace/${id}/`,
                formData, {
                headers: this.getHeaders()
            }).toPromise()
    }
}