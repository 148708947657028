import { animate, state, style, transition, trigger, query, animateChild, group } from '@angular/animations';

export const fader = trigger('routeAnimations', [
    transition('* <=> *', [
        // Set a default  style for enter and leave
        query(':enter, :leave', [
            style({
                position: 'absolute',
                left: 0,
                width: '100%',
                opacity: 0,
                transform: 'scale(0) translateY(100%)',
            }),
        ]),
        // Animate the new page in
        query(':enter', [
            animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
        ])
    ]),
]);

function slideTo(direction) {
    const optional = { optional: true };
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                [direction]: 0,
                width: '100%'
            })
        ], optional),
        query(':enter', [
            style({ [direction]: '-100%' })
        ]),
        group([
            query(':leave', [
                animate('600ms ease', style({ [direction]: '100%' }))
            ], optional),
            query(':enter', [
                animate('600ms ease', style({ [direction]: '0%' }))
            ])
        ]),
        // Normalize the page style... Might not be necessary

        // Required only if you have child animations on the page
        //  query(':leave', animateChild()),
        //  query(':enter', animateChild()),
    ];
}

export const slider =
    trigger('routeAnimations', [
        transition(':increment', slideTo('right')),
        transition(':decrement', slideTo('left')),
    ]);

export const formErrorAnimation = trigger('transitionMessage', [
    state('enter', style({ opacity: 1, transform: 'translateY(0%)' })),
    transition('void => enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('300ms cubic-bezier(0.55, 0, 0.55, 0.2)')]),
]);


