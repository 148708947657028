import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CharacterService } from 'src/app/services/character.service';

@Component({
    selector: 'app-add-character-dialog',
    templateUrl: './add-character-dialog.component.html',
    styleUrls: ['./add-character-dialog.component.scss']
})
export class AddCharacterDialogComponent implements OnInit {
    stories;
    characters;
    filteredCharacters= [];
    selectedCharacters = [];
    tableHeight;
    constructor(
        private characterService: CharacterService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) { }

    ngOnInit(): void {

        this.initData();
    }
    
    initData() {
            this.characterService.getList().then((res: any) => {
                if(res.success) {
                    this.characters = res.storycharacters;
                    this.characters.forEach(char => {
                        if (this.config.data.storyCharacters.some(character => char.id === character.id)) {
                            return
                        } else {
                            this.filteredCharacters.push(char);
                        }
                    })
                }
              
            });
    }

    addAndClose() {
        this.ref.close(this.selectedCharacters);
    }
}
