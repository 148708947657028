import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit {

  constructor(
      private router: Router,
      private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
  }

  confirmExit(route) {
      
  }

}
