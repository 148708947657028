import { Injectable } from '@angular/core';
import { AbstractApiService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppInitService extends AbstractApiService {

    constructor(
        http: HttpClient,
        private router: Router
    ) {
        super(http);
        this.apiUrl = 'dashboardApi/';
    }

    init(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // try get configuration if user is logged.
            this.http.get(
                `${this.getApiUrl()}configuration/`,
                {
                    headers: this.getHeaders()
                }
            ).toPromise().then((response: any) => {

                // get current user data
                this.http.get(
                    `${this.getApiUrl()}user/`,
                    {
                        headers: this.getHeaders()
                    }
                ).toPromise().then((response: any) => {
                    // check user is able to stay loggenin
                    if (response.user.is_superuser) {
                        environment.currentUser = response.user;
                        resolve();
                    } else if (response.user.is_staff) {
                        // check if not superuser has almost one group;
                        if (response.user.groups.length > 0) {
                            environment.currentUser = response.user;
                            resolve();
                        } else {
                            console.log("User has no group!");
                            this.logout();
                            resolve();
                        }
                    } else {
                        console.log("User is not staff/superuser!");
                        this.logout();
                        resolve();
                    }
                }).catch((err) => {
                    // delete user token
                    this.logout();
                    resolve();
                });


            }).catch((err) => {
                // delete user token
                this.logout();
                resolve();
            });
        });
    }

    logout() {
        environment.currentUser = null;
        localStorage.removeItem('token');
        this.router.navigate(['']);
    }
}