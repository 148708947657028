import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {AbstractApiService} from './base.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthenticationService extends AbstractApiService {

    private isLoggedInChecked: boolean = false;

    constructor(private router: Router,
                http: HttpClient) {
        super(http);
        this.apiUrl = 'api/auth';
    }

    logout() {
        environment.currentUser = null;
        localStorage.removeItem('token');
        this.router.navigate(['']);
    }

    login(user) {
        /*
        * login user with logic
        */
        environment.currentUser = null;
        return this.http
            .post(`${this.getApiUrl()}/login/`, {
                username: user.username,
                password: user.password,
            })
            .toPromise()
            .then((response: any) => {
                if (response.success) {
                    if (response.user.is_superuser) {
                        environment.currentUser = response.user;
                        localStorage.setItem('token', response.token);
                        return response;
                    } else {
                        if (response.user.is_staff) {
                            // check if not superuser has almost one group;
                            if (response.user.groups.length > 0) {
                                environment.currentUser = response.user;
                                localStorage.setItem('token', response.token);
                                return response;
                            } else {
                                console.log("User has no group!");
                                throw new Error("User has no group!");
                            }
                        } else {
                            console.log("User is not staff!");
                            throw new Error("User is not staff!");
                        }
                        
                    }
                }
            })
            .catch(err => {
                return err;
            });
    }

    testLogin(user) {
        /*
        * Test login user
        */
        return this.http
            .post(`${this.getApiUrl()}/login/`, {
                username: user.username,
                password: user.password,
            })
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch(err => {
                return err;
            });
    }

    isLoggedIn() {
        return localStorage.getItem('token') ? true : false;
    }

}
