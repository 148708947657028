import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from './rest.service';

@Injectable()
export class ImageService extends RestService {

    constructor(http: HttpClient) {
        super(http, 'dashboardApi');

    }

    getList() {
        return this.http
            .get(`${this.getApiUrl()}/imagemedias/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    create(formData) {
        return this.http
            .post(`${this.getApiUrl()}/imagemedia-create/`,
                formData,
                {
                    headers: this.getHeaders()
                }).toPromise()
    }

    delete(id) {
        return this.http
            .delete(`${this.getApiUrl()}/imagemedia/${id}/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    get(id) {
        return this.http
            .get(`${this.getApiUrl()}/imagemedia/${id}/`, {
                headers: this.getHeaders()
            }).toPromise()
    }

    update(id, formData) {
        return this.http
            .post(`${this.getApiUrl()}/imagemedia/${id}/`,
                formData, {
                    headers: this.getHeaders()
                }).toPromise()
    }

    duplicate(data) {
        return this.http
            .post(`${this.getApiUrl()}/imagemedia-duplicate/`,
                data, {
                headers: this.getHeaders()
            }).toPromise()
    }
}