import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService extends RestService {

    constructor(http: HttpClient) {
        super(http, 'dashboardApi/user');
    }

    get() {
        return this.http
            .get(`${this.getApiUrl()}/`,
                {
                    headers: this.getHeaders()
                }).toPromise();
    }

    update(data: {}) {
        return this.http
            .post(
                `${this.getApiUrl()}/`,
                data,
                {
                    headers: this.getHeaders()
                })
            .toPromise();
    }
}
