<p-toolbar>
    <div class="p-toolbar-group-left" routerLink="/story-catalog">
       <img src="/assets/logo_color.svg" alt="Guilt Admin Panel" width="30">
    </div>

    <div class="p-toolbar-group-right" *ngIf="user">
        <div>Benvenuto {{user.username}}</div>
        <div>
            <button pButton type="button" label="Cambia password" class="p-button-info p-button-text" (click)="confirmExit('/password-change')"></button>
        </div>
        <div>
            <button pButton type="button" label="ESCI" class="p-button-info p-button-text" (click)="logout()"></button>
        </div>
    </div>
</p-toolbar>